import mediator from "libs/mediator";
import Utils from "../../Utils";

export default (function() {
  let modal: HTMLElement;
  let backdrop: HTMLElement;
  let closeButton: HTMLElement;
  const modalHidden = "modal--hidden";
  const backdropVisible = "backdrop--visible";
  const modalOpen = "modal-open";
  const body = document.body;

  function show() {
    Utils.log("Show Modal");

    backdrop.classList.add(backdropVisible);
    modal.classList.remove(modalHidden);
    body.classList.add(modalOpen);
    mediator.trigger("lazy_images:update");
    handleCloseBtnPositioning();
  }

  function handleCloseBtnPositioning() {
    if (!modal) return false;

    const disclaimer =  modal.querySelector(".modal__expired-disclaimer, .modal-clickout__expired-disclaimer") as HTMLElement;
    const closeBtn = modal.querySelector(".modal__close, .modal__close-btn") as HTMLElement;

    if (closeBtn && disclaimer) {
      closeBtn.style.top = `${disclaimer.offsetHeight + 5}px`;
    } else if (window.modalCloseBtnTop) {
      closeBtn.style.top = `${window.modalCloseBtnTop}px`;
    }
  }

  function hide() {
    Utils.log("Hide Modal");

    backdrop.classList.remove(backdropVisible);
    modal.classList.add(modalHidden);
    body.classList.remove(modalOpen);
  }

  function run() {
    Utils.log("Modals module init");

    modal = document.getElementById("my-modal") as HTMLElement;
    backdrop = document.querySelector(".backdrop") as HTMLElement;
    closeButton = document.querySelector(".modal__close-btn") as HTMLElement;

    [closeButton, backdrop].forEach(item => {
      item.addEventListener("click", () => {
        mediator.trigger("modal:close");
        document.dispatchEvent(new Event("modal:close"));
      });
    });

    mediator.on("modal:show", show);
    mediator.on("modal:close", hide);
  }


  return {
    run
  };

})();
