/**
 * Small helper for detecting device type
 * @type {{mobile, smallMobile, tablet, tabletLandscape, tabletPortrait, desktop}}
 * @return {Boolean}
 *
 * @example
 * if (ismobile.mobile()) {
 *   doSomethingOnMobileOnly();
 * }
 */

export default (function() {
  const mobile = "(max-width: 767px)";
  const smallMobile = "(max-width: 320px)";

  const tablet = "(min-width: 768px) and (max-width: 1024px)";
  const tabletLandscape = "(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)";
  const tabletPortrait = "(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)";

  const desktop = "(min-width: 1025px)";

  return {
    mobile: (): boolean => {
      return window.matchMedia(mobile).matches;
    },
    smallMobile: (): boolean => {
      return window.matchMedia(smallMobile).matches;
    },
    tablet: (): boolean => {
      return window.matchMedia(tablet).matches;
    },
    tabletLandscape: (): boolean => {
      return window.matchMedia(tabletLandscape).matches;
    },
    tabletPortrait: (): boolean => {
      return window.matchMedia(tabletPortrait).matches;
    },
    desktop: (): boolean => {
      return window.matchMedia(desktop).matches;
    }
  };
})();
